import React, { useState, useRef, useEffect } from "react";
// import Helmet from 'react-helmet';
// import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import { Link } from "gatsby";

import { List, ListItem, ListItemText } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";


import Grid from "@material-ui/core/Grid";
import NiinaHeader from "../components/NiinaHeader";
import NiinaFooter from "../components/NiinaFooter";
import { Helmet } from "react-helmet-async";

function Tips() {
  // const classes = useStyles();

  return (
    <div style={{ minWidth: "320px" }}>
      <Helmet>
        <html lang="ja" />
        <title>
          焼き立ての「いいな」を届けるパン屋 『Bakery
          Niina(ベーカリー・ニーナ)』 | 美味しい食べ方
        </title>
        <meta
          name="description"
          content="山形のみなさんに、パン職人が一晩かけて仕込むこだわりパンを一番おいしい状態で届けたい。焼き立てパンが香る店『Bakery Niina（ベーカリーニーナ）』です。多い日には１日５０回焼き上げるため、いつでも焼きたてのパンが楽しめます。"
        />
      </Helmet>
      <NiinaHeader />
      <TipsComponent />
      <NiinaFooter />
    </div>
  );
}

export default Tips;

function TipsComponent() {
  return (
    <div className="App">
      <div
        style={{
          display: "row",
        }}
      >
        <StaticImage
          style={{
            marginTop: "1em",
            minWidth: "160px",
            maxWidth: "30%",
          }}
          src="../images//img/photos/ヘッダーロゴ@3x.png"
        />
      </div>

      <div
        style={{
          marginTop: "3.2em",
          display: "row",
          fontSize: mainFontSize,
        }}
      >
        <StaticImage
          style={{ minWidth: "160px", maxWidth: "70%" }}
          src="../images//img/photos/tips@3x.png"
        />
      </div>

      <div
        style={{
          display: "row",
          fontSize: mainFontSize,
          marginBottom: "3em",
        }}
      >
        <p>
          パンはなるべく早くお召し上がりください。
          <br />
          もし残ってしまった場合の当店のおすすめの
          <br />
          保存方法をご紹介いたします。
        </p>
      </div>

      <div
        style={{
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          style={{
            minWidth: "5%",
            maxWidth: "50%",
            width: "15vw",
            //                  marginBottom: "1.8em",
          }}
          src="../images//img/photos/クロワッサン@3x.png"
        />
      </div>

      <div
        style={{
          display: "row",
          fontSize: mainFontSize,
          //                marginBottom: "1em",
        }}
      >
        <p>
          パンは鮮度が命。
          <br />
          当日中にお召し上がりでない場合は
          <br />
          早めの冷凍保存を！
        </p>
      </div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          fontSize: mainFontSize,
        }}
      >
        <p
          style={{
            textAlign: "left",
            padding: 0,
            margin: 0,
            marginLeft: "1.5em",
            marginRight: "1.5em",
            width: "100%",
            marginBottom: "2.5vw",
          }}
        >
          <span style={{ fontSize: "2.5vw", marginRight: "3vw" }}>●</span>
          食パン、バゲットは召し上がるサイズにカット、小さめのパンは一個そのままラップで包みます（この時ジッパー付きの保存袋にいれ、空気を抜くのがベスト！）
        </p>
      </div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          fontSize: mainFontSize,
          marginBottom: "4.125vw",
        }}
      >
        <p
          style={{
            textAlign: "left",
            padding: 0,
            margin: 0,
            marginLeft: "1.5em",
            marginRight: "1.5em",
            width: "100%",
          }}
        >
          <span style={{ fontSize: "2.5vw", marginRight: "3vw" }}>●</span>
          冷凍庫へ保存して２～３週間以内にお召し上がりください。焼き立ての美味しさを保つにはお買い上げ後すぐの冷凍がおすすめです。
        </p>
      </div>

      <div
        style={{
          display: "row",
          fontSize: "3.125v",
        }}
      >
        <StaticImage
          style={{
            minWidth: "5%",
            maxWidth: "50%",
            width: "15vw",
            //                    marginBottom: "1.8em",
          }}
          src="../images//img/photos/クロワッサン@3x.png"
        />
      </div>

      <div
        style={{
          display: "row",
          fontSize: mainFontSize,
          //                  marginBottom: "1em",
        }}
      >
        <p>解凍のポイント</p>
      </div>

      <h2
        style={{
          padding: 0,
          margin: 0,
          marginLeft: "0.8em",
          display: "flex",
          fontSize: "4vw",
        }}
      >
        そのまま召し上がる場合
      </h2>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          fontSize: mainFontSize,
        }}
      >
        <p
          style={{
            textAlign: "left",
            padding: 0,
            margin: 0,
            marginLeft: "1.5em",
            marginRight: "1.5em",
            width: "100%",
            marginBottom: "2.5vw",
          }}
        >
          <span style={{ fontSize: "2.5vw", marginRight: "3vw" }}>●</span>
          お召し上がりの前にラップに包んだまま１～２時間ほど自然解凍してください。
        </p>
      </div>

      <h2
        style={{
          padding: 0,
          margin: 0,
          marginLeft: "0.8em",
          display: "flex",
          fontSize: "4vw",
        }}
      >
        トーストする場合
      </h2>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          fontSize: mainFontSize,
        }}
      >
        <p
          style={{
            textAlign: "left",
            padding: 0,
            margin: 0,
            marginLeft: "1.5em",
            marginRight: "1.5em",
            width: "100%",
            marginBottom: "2.5vw",
          }}
        >
          <span style={{ fontSize: "2.5vw", marginRight: "3vw" }}>●</span>
          自然解凍後、オーブントースターを200～220℃に温めトーストします。
        </p>
      </div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          fontSize: mainFontSize,
        }}
      ></div>

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          fontSize: mainFontSize,
          marginBottom: "3em",
        }}
      >
        <p
          style={{
            textAlign: "left",
            padding: 0,
            margin: 0,
            marginLeft: "1.5em",
            marginRight: "1.5em",
            width: "100%",
          }}
        >
          <span style={{ fontSize: "2.5vw", marginRight: "3vw" }}>●</span>
          焼く前に霧吹きで表面に少し水を吹き付けて焼くと、しっとり、ふんわりと焼きあがります。
        </p>
      </div>
    </div>
  );
}

{
  /* function Routes(){

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    window.setTimeout(() => {
      if(process.env.NODE_ENV !== "production"){ return }
      window.gtag('config', 'UA-171489536-1', {
        'page_path': `${location.pathname}${location.search}`
      });
    });
  });
} */
}

const mainFontSize = "3.8vw";
